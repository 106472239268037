import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPrint, faTrash } from "@fortawesome/free-solid-svg-icons";
import CommonTestForm from "../commonTestForm";

const host = process.env.REACT_APP_HOST;

export const LipidProfil = () => {
  const [tests, setTests] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [additionalData, setAdditionalData] = useState({
    cholestrol: "",
    triglyceride: "",
    hdl: "",
    ldl: "",
  });

  // Function to fetch tests data
  const fetchTests = async () => {
    try {
      const response = await fetch(
        `${host}/haematology/LipidProfil/fetchall/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Fetch failed with status ${response.status}`);
      }

      const json = await response.json();
      setTests(json);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTests();
  }, []);

  // Function to handle form submission
  const handleAsoSubmit = async (commonFormData) => {
    const formData = {
      ...commonFormData,
      additionalData: additionalData,
    };

    try {
      const response = await fetch(`${host}/haematology/LipidProfil/addTest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const json = await response.json();
        const test = json;
        setTests([...tests, test]); // Update tests with the new test
        console.log("Form data submitted successfully");
      } else {
        console.error("Failed to submit form data");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    // console.log("Combined Form Data:", formData);
  };

  // Function to handle additional data input change
  const handleAdditionalDataChange = (e) => {
    const { name, value } = e.target;
    setAdditionalData({ ...additionalData, [name]: value });
  };

  // Function to open the edit modal and set the selected test
  const handleEditClick = (test) => {
    setSelectedTest(test);
    setShowEditModal(true);
  };

  // Function to close the edit modal
  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleEditFieldChange = (e) => {
    const { name, value } = e.target;

    // Update the selectedTest state with the new value for the field.
    setSelectedTest({
      ...selectedTest,
      additionalData: {
        ...selectedTest.additionalData,
        [name]: value,
      },
      [name]: value,
    });
  };

  // Function to handle form submission for editing
  const handleEditSubmit = async () => {
    try {
      const response = await fetch(
        `${host}/haematology/LipidProfil/editTest/${selectedTest._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(selectedTest), // Send the selectedTest data for editing
        }
      );

      if (response.ok) {
        const updatedTests = tests.map((test) =>
          test._id === selectedTest._id ? selectedTest : test
        );
        setTests(updatedTests);
        console.log("Test edited successfully");
      } else {
        console.error("Failed to edit test");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }

    // Close the edit modal after submission
    handleCloseEditModal();
  };

  // Function to handle test deletion
  const handleDelete = async (testId) => {
    try {
      const response = await fetch(
        `${host}/haematology/LipidProfil/deleteTest/${testId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Remove the deleted test from the tests array
        const updatedTests = tests.filter((test) => test._id !== testId);
        setTests(updatedTests);
        console.log("Test deleted successfully");
      } else {
        console.error("Failed to delete test");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  // Function to conditionally add a table row
  function addTableRow(label, value, unit, refRange) {
    if (value !== "") {
      return `
    <tr>
      <td>${label}:</td>
      <td><strong>${value}</strong></td>
      <td><strong>${unit}</strong></td>
      <td><strong>${refRange}</strong></td>
    </tr>
  `;
    }
    return "";
  }

  // Function to print test results
  const handlePrint = (test) => {
    const printWindow = window.open("", "");
    printWindow.document.write(
      `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Lab Test Results</title>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css">
        <style>
            .footer {
                position: absolute;
                color: red;
                bottom: 0;
                text-align: center;
                width: 100%;
            }
        </style>
    </head>
    <body>
        <div class="container-fluid mt-4">
            <h1 class="text-center mb-3"><span class="text-danger">RS</span> Lab Center</h1>
            <div class="table-responsive mt-4">
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <th class="col-sm-12 text-center" colspan="2"><strong>Patient Information</strong></th>
                        </tr>
                        <tr>
                            <td class="col-sm-6"><strong>Test ID:</strong> ${
                              test.labNo
                            }</td>
                            <td class="col-sm-6"><strong>Test Name:</strong> ${
                              test.test
                            }</td>
                        </tr>
                        <tr>
                            <td class="col-sm-6"><strong>Patient Name:</strong> ${
                              test.fullName
                            }</td>
                            <td class="col-sm-6"><strong>Gender:</strong> ${
                              test.gender
                            }</td>
                        </tr>
                        <tr>
                            <td class="col-sm-6"><strong>Referred By:</strong> ${
                              test.referred
                            }</td>
                            <td class="col-sm-6"><strong>Test Date:</strong> ${
                              test.date
                            }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h2 class="text-center text-danger mt-5">Lab Test Results</h2>
            <div class="table-responsive mt-4">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Test</th>
                            <th>Result</th>
                            <th>Units</th>
                            <th>Ref Range</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${addTableRow(
                          "CHOLESTEROL",
                          test.additionalData.cholestrol,
                          "mg/ul",
                          "(150 - 240)"
                        )}
                        ${addTableRow(
                          "TRIGLYCERIDES",
                          test.additionalData.triglyceride,
                          "mg/ul",
                          "(50 - 165)"
                        )}
                        ${addTableRow(
                          "HDL",
                          test.additionalData.hdl,
                          "mg/ul",
                          "(> 35)"
                        )}
                        ${addTableRow(
                          "LDL",
                          test.additionalData.ldl,
                          "mg/ul",
                          "(< 160)"
                        )}
                    </tbody>
                </table>
            </div>
        </div>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>
        <div class="footer">
            <p>Designed By <strong>Muhammad Ammar</strong></p>
        </div>
    </body>
    </html>
    `
    );
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <Container className="px-5 pb-5 text-white" style={{ minHeight: "90vh" }}>
      <CommonTestForm onSubmit={(formData) => handleAsoSubmit(formData)} />
      <Container>
        <Row className="mt-5">
          <h3>Test Results</h3>

          <Col sm={6}>
            <Form.Group className="my-3" controlId="formCholestrol">
              <Form.Label>Cholestrol</Form.Label>
              <Form.Control
                type="text"
                name="cholestrol"
                placeholder=""
                value={additionalData.cholestrol}
                onChange={handleAdditionalDataChange}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="my-3" controlId="formTriglycerides">
              <Form.Label>Triglyceride</Form.Label>
              <Form.Control
                type="text"
                name="triglyceride"
                placeholder=""
                value={additionalData.triglyceride}
                onChange={handleAdditionalDataChange}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="my-3" controlId="formHdl">
              <Form.Label>HDL</Form.Label>
              <Form.Control
                type="text"
                name="hdl"
                placeholder=""
                value={additionalData.hdl}
                onChange={handleAdditionalDataChange}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="my-3" controlId="formLdl">
              <Form.Label>LDL</Form.Label>
              <Form.Control
                type="text"
                name="ldl"
                placeholder=""
                value={additionalData.ldl}
                onChange={handleAdditionalDataChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Table variant="dark" bordered responsive className="mt-4">
          <thead>
            <tr>
              <th className="bg-primary">ID</th>
              <th className="bg-primary">Test Name</th>
              <th className="bg-primary">Patient Name</th>
              {/* <th className="bg-primary">Gender</th> */}
              {/* <th className="bg-primary">Referred By</th> */}
              <th className="bg-primary">Cholestrol</th>
              <th className="bg-primary">Triglycerides</th>
              <th className="bg-primary">HDL</th>
              <th className="bg-primary">LDL</th>
              <th className="bg-primary">Test Date</th>
              <th className="bg-primary">Actions</th>
            </tr>
          </thead>
          <tbody>
            {tests.map((test) => (
              <tr key={test._id}>
                <td>{test.labNo}</td>
                <td>{test.test}</td>
                <td>{test.fullName}</td>
                {/* <td>{test.gender}</td> */}
                {/* <td>{test.referred}</td> */}
                <td>{test.additionalData?.cholestrol}</td>
                <td>{test.additionalData?.triglyceride}</td>
                <td>{test.additionalData?.hdl}</td>
                <td>{test.additionalData?.ldl}</td>

                {test.date && (
                  <td>{new Date(test.date).toLocaleDateString("en-US")}</td>
                )}
                <td className="d-flex">
                  <Button
                    variant="success"
                    className="me-2"
                    onClick={() => handleEditClick(test)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                  <Button
                    variant="danger"
                    className="me-2"
                    onClick={() => handleDelete(test._id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                  <Button variant="primary" onClick={() => handlePrint(test)}>
                    <FontAwesomeIcon icon={faPrint} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <Modal
        show={showEditModal}
        onHide={handleCloseEditModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col sm={6}>
                {" "}
                <Form.Group controlId="formFullnameEdit">
                  <Form.Label>Patient Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="fullname"
                    value={selectedTest ? selectedTest.fullName : ""}
                    onChange={handleEditFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="editFullName">
                  <Form.Label>Referred By:</Form.Label>
                  <Form.Control
                    as="select"
                    name="referred"
                    value={selectedTest?.referred || ""}
                    onChange={(e) => handleEditFieldChange(e)}
                  >
                    <option>Select Doctor</option>
                    <option value="umar">Dr.Muhammad Umar</option>
                    <option value="karim">Dr.Abdul Karim Afridi</option>
                    <option value="zarlasht">Dr.Zarlasht Yousafzai</option>
                    <option value="junaid">Dr.Syed Muhammad Junaid</option>
                    <option value="other">Other</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="gender">
                  <Form.Label>Gender:</Form.Label>
                  <Form.Control
                    as="select"
                    name="gender"
                    value={selectedTest?.gender || ""}
                    onChange={(e) => handleEditFieldChange(e)}
                  >
                    <option>Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={6}>
                {" "}
                <Form.Group controlId="formCholestrol">
                  <Form.Label>cholestrol</Form.Label>
                  <Form.Control
                    type="text"
                    name="cholestrol"
                    value={
                      selectedTest ? selectedTest.additionalData.cholestrol : ""
                    }
                    onChange={handleEditFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                {" "}
                <Form.Group controlId="formtriglyceride">
                  <Form.Label>triglyceride</Form.Label>
                  <Form.Control
                    type="text"
                    name="triglyceride"
                    value={
                      selectedTest
                        ? selectedTest.additionalData.triglyceride
                        : ""
                    }
                    onChange={handleEditFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                {" "}
                <Form.Group controlId="formhdl">
                  <Form.Label>hdl</Form.Label>
                  <Form.Control
                    type="text"
                    name="hdl"
                    value={selectedTest ? selectedTest.additionalData.hdl : ""}
                    onChange={handleEditFieldChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                {" "}
                <Form.Group controlId="formldl">
                  <Form.Label>ldl</Form.Label>
                  <Form.Control
                    type="text"
                    name="ldl"
                    value={selectedTest ? selectedTest.additionalData.ldl : ""}
                    onChange={handleEditFieldChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
