import "../../assets/css/page.css";

import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const tests = [
  {
    testName: "Blood Group / Hb%",
    link: "./Forms/Biochemistry/BloodGroupHb",
  },
  {
    testName: "Blood Group",
    link: "./Forms/Biochemistry/BloodGroup",
  },
  {
    testName: "Full Blood Group",
    link: "./Forms/Biochemistry/FullBloodGroup",
  },
  {
    testName: "Hb%",
    link: "./Forms/Biochemistry/Hb",
  },
  {
    testName: "Urine R/E",
    link: "./Forms/Biochemistry/UrineRE",
  },
  {
    testName: "Stool R/E",
    link: "./Forms/Biochemistry/StoolRE",
  },
  {
    testName: "Chemistry",
    link: "./Forms/Biochemistry/Chemistry",
  },
  {
    testName: "Pregnancy",
    link: "./Forms/Biochemistry/Pregnancy",
  },
  {
    testName: "RBS",
    link: "./Forms/Biochemistry/Rbs",
  },
  {
    testName: "ASO / RA",
    link: "./Forms/Biochemistry/AsoRa",
  },
  {
    testName: "Widal / TO",
    link: "./Forms/Biochemistry/WidalTo",
  },
  {
    testName: "ASO",
    link: "./Forms/Biochemistry/ASO",
  },
  {
    testName: "RA Factor",
    link: "./Forms/Biochemistry/RaFactor",
  },
  {
    testName: "HBS / HCV",
    link: "./Forms/Biochemistry/HbsHcv",
  },
  {
    testName: "HB/ HBS / HCV",
    link: "./Forms/Biochemistry/HbHbsHcv",
  },
  {
    testName: "HBS / HCV / RBS",
    link: "./Forms/Biochemistry/HbsHcvRbs",
  },
  {
    testName: "HB/BT/CT",
    link: "./Forms/Biochemistry/HbBtCt",
  },
  {
    testName: "MT",
    link: "./Forms/Biochemistry/Mt",
  },
  {
    testName: "GYNEA WARD",
    link: "./Forms/Biochemistry/GyneaWard",
  },
];

export default function Biochemistry() {
  const itemsPerRow = 5;
  const rows = tests.reduce((acc, test, index) => {
    const rowIndex = Math.floor(index / itemsPerRow);
    if (!acc[rowIndex]) {
      acc[rowIndex] = [];
    }
    acc[rowIndex].push(test);
    return acc;
  }, []);

  return (
    <section id="Biochemistry" className="Biochemistry">
      <Container fluid className="px-2">
        <Row className="text-white">
          <Col md={6}>
            <div className="text-start">
              <h2>Biochemistry</h2>
            </div>
          </Col>
          <Col md={6}>
            <div className="text-end mt-2">
              <p>Home/Biochemistry</p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="py-1 mb-4">
        <Row>
          <Card bg="dark" className="text-white">
            <Card.Header className="bg-primary text-dark">
              <h4>Biochemistry Test</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="p-0">
                  <Table variant="dark" bordered hover>
                    <tbody>
                      {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((test, colIndex) => (
                            <td key={colIndex}>
                              <Link className="btn btn-danger" to={test.link}>
                                {test.testName}
                              </Link>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </section>
  );
}
