import { Card, Col, Container, Row, Table } from "react-bootstrap";
import "../../assets/css/page.css";

import { Link } from "react-router-dom";

const tests = [
  {
    testName: "Lipid Profil",
    link: "./Forms/Haematology/LipidProfil",
  },
  {
    testName: "Semen Analysis",
    link: "./Forms/Haematology/SemenAnalysis",
  },
  {
    testName: "Sputum For AFB",
    link: "./Forms/Haematology/SputumForAfb",
  },
  {
    testName: "Serum Amylase",
    link: "./Forms/Haematology/SerumAmylase",
  },
  {
    testName: "Plat Count",
    link: "./Forms/Haematology/PlatCount",
  },
  {
    testName: "S Electrolyte",
    link: "/Forms/Haematology/SElectrolyte",
  },
  {
    testName: "Calcium",
    link: "./Forms/Haematology/Calcium",
  },
  {
    testName: "HBS/HCV/HIV/VD",
    link: "./Forms/Haematology/HbsHcvHivVd",
  },
  {
    testName: "New X Matching",
    link: "./Forms/Haematology/NewXMatching",
  },
  {
    testName: "Peripheral Smear",
    link: "./Forms/Haematology/PeripheralSmear",
  },
  {
    testName: "HB/BT/CT/BG",
    link: "./Forms/Haematology/HbBtCtBg",
  },
  {
    testName: "ANF",
    link: "./Forms/Haematology/ANF",
  },
  {
    testName: "MP",
    link: "./Forms/Haematology/MP",
  },
  {
    testName: "SBR",
    link: "./Forms/Haematology/SBR",
  },
  {
    testName: "BT/CT",
    link: "./Forms/Haematology/BtCt",
  },
  {
    testName: "ESR",
    link: "./Forms/Haematology/ESR",
  },
  {
    testName: "CPK Cardia",
    link: "./Forms/Haematology/CpkCardia",
  },
  {
    testName: "PT APTT",
    link: "./Forms/Haematology/PtAptt",
  },
  {
    testName: "X Matching",
    link: "./Forms/Haematology/XMatching",
  },
];

export default function Haematology(props) {
  const itemsPerRow = 5;

  // Split the tests into rows with 5 columns each
  const rows = tests.reduce((acc, test, index) => {
    const rowIndex = Math.floor(index / itemsPerRow);
    if (!acc[rowIndex]) {
      acc[rowIndex] = [];
    }
    acc[rowIndex].push(test);
    return acc;
  }, []);

  return (
    <section id="Haematology" className="Haematology">
      <Container fluid className="px-2">
        <Row className="text-white">
          <Col md={6}>
            <div className="text-start">
              <h2>Haematology</h2>
            </div>
          </Col>
          <Col md={6}>
            <div className="text-end mt-2">
              <p>Home/Haematology</p>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <Button variant="primary">Launch demo modal</Button> */}

      <Container fluid className="py-1 mb-4">
        <Row>
          <Card bg="dark" className="text-white">
            <Card.Header className="bg-primary text-dark">
              <h4>Haematology Test</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="p-0">
                  <Table variant="dark" bordered hover>
                    <tbody>
                      {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((test, colIndex) => (
                            <td key={colIndex}>
                              <Link className="btn btn-danger" to={test.link}>
                                {test.testName}
                              </Link>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </section>
  );
}
