import { Link } from "react-router-dom";
import "../../assets/css/sidebar.css";
import { useContext } from "react";
import { AuthContext } from "../Routes/protectedRoute/AuthContext";

export default function Sidebar() {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <section id="sidebar" className="sidebar">
      {isLoggedIn && (
        <ul align="start">
          <li>
            <Link to="/">
              <i className="icon fas fa-gauge"></i>
              DashBoard
            </Link>
          </li>
          <li>
            <Link to="/biochemistry">
              <i className="icon fas fa-flask-vial"></i>
              BioChemistry
            </Link>
          </li>
          <li>
            <Link to="/specialTest">
              <i className="icon fas fa-vial"></i>Special Test
            </Link>
          </li>
          <li>
            <Link to="/routineTest">
              <i className="icon fas fa-flask"></i>
              Routine Test
            </Link>
          </li>
          <li>
            <Link to="/serology">
              <i className="icon fas fa-vial-virus"></i>
              Serology
            </Link>
          </li>
          <li>
            <Link to="/haematology">
              <i className="icon fas fa-microscope"></i>Haematology
            </Link>
          </li>
          <li>
            <Link to="/user">
              <i className="icon fas fa-user"></i>User Roles
            </Link>
          </li>
        </ul>
      )}
    </section>
  );
}
