import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";

const host = process.env.REACT_APP_HOST; // Replace with your API host

const User = () => {
  const [users, setUsers] = useState([]);
  const [updatedUser, setUpdatedUser] = useState({ email: "", password: "" });
  const [addedUser, setAddedUser] = useState({});
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${host}/user/fetchUsers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        console.error("Failed to fetch users.");
      }
    } catch (error) {
      console.error("Failed to fetch users: ", error);
    }
  };

  const handleCreateUser = async () => {
    try {
      const response = await fetch(`${host}/user/addUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(addedUser),
      });
      if (response.ok) {
        const newUser = await response.json();
        setUsers([...users, newUser]);
        setShowAddUserModal(false);
      } else {
        console.error("Failed to create user.");
      }
    } catch (error) {
      console.error("Error while creating user: ", error);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      const response = await fetch(`${host}/user/deleteUser/${userId}`, {
        method: "DELETE",
      });
      if (response.ok) {
        setUsers(users.filter((user) => user._id !== userId));
      } else {
        console.error("Failed to delete user.");
      }
    } catch (error) {
      console.error("Failed to delete user: ", error);
    }
  };

  const handleUpdateUser = async () => {
    try {
      const response = await fetch(
        `${host}/user/updateUser/${updatedUser._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedUser),
        }
      );
      if (response.ok) {
        const updatedUserData = await response.json();
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === updatedUserData._id ? updatedUserData : user
          )
        );
        setShowEditUserModal(false);
      } else {
        console.error("Failed to update user.");
      }
    } catch (error) {
      console.error("Error while updating user: ", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleEditUser = (user) => {
    setUpdatedUser({
      _id: user._id,
      email: user.email,
      password: user.password,
    });
    setShowEditUserModal(true);
  };

  const handleAddUser = () => {
    setAddedUser({
      email: "",
      password: "",
    });
    setShowAddUserModal(true);
  };

  return (
    <section id="User" className="User">
      <Container fluid className="px-2">
        <Row className="text-white">
          <Col md={6}>
            <div className="text-start">
              <h2>Users</h2>
            </div>
          </Col>
          <Col md={6}>
            <div className="text-end mt-2">
              <p>Home/Users</p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="py-1 mb-4">
        <Row>
          <Card bg="dark" className="text-white">
            <Card.Header className="bg-primary text-dark">
              <h4>Users</h4>
              <Button variant="success" onClick={handleAddUser}>
                Add User
              </Button>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="p-0">
                  <Table bordered variant="dark">
                    <thead>
                      <tr>
                        <th>Email</th>
                        <th>Password</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => (
                        <tr key={user._id}>
                          <td>{user.email}</td>
                          <td>{user.password}</td>
                          <td className="bg-danger">
                            <FontAwesomeIcon
                              icon={faEdit}
                              onClick={() => handleEditUser(user)}
                              style={{ cursor: "pointer", marginRight: "10px" }}
                            />
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => handleDeleteUser(user._id)}
                              style={{ cursor: "pointer", marginRight: "10px" }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>

      {/* Add User Modal */}
      <Modal show={showAddUserModal} onHide={() => setShowAddUserModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group controlId="addUserEmail">
                  <Form.Label>Email:</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={addedUser.email}
                    onChange={(e) =>
                      setAddedUser({ ...addedUser, email: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="addUserPassword">
                  <Form.Label>Password:</Form.Label>
                  <Form.Control
                    type="text"
                    name="password"
                    placeholder="Password"
                    value={addedUser.password}
                    onChange={(e) =>
                      setAddedUser({ ...addedUser, password: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddUserModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateUser}>
            Add User
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit User Modal */}
      <Modal
        show={showEditUserModal}
        onHide={() => setShowEditUserModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group controlId="editUserEmail">
                  <Form.Label>Email:</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={updatedUser.email}
                    onChange={(e) =>
                      setUpdatedUser({ ...updatedUser, email: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="editUserPassword">
                  <Form.Label>Password:</Form.Label>
                  <Form.Control
                    type="text"
                    name="password"
                    placeholder="Password"
                    value={updatedUser.password}
                    onChange={(e) =>
                      setUpdatedUser({
                        ...updatedUser,
                        password: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowEditUserModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateUser}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default User;
