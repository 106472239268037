import { useParams } from "react-router-dom";
import {
  ASO,
  AsoRa,
  BloodGroup,
  BloodGroupHb,
  Chemistry,
  FullBloodGroup,
  GyneaWard,
  Hb,
  HbBtCt,
  HbHbsHcv,
  HbsHcv,
  HbsHcvRbs,
  Mt,
  Pregnancy,
  Rbs,
  RaFactor,
  StoolRE,
  UrineRE,
  WidalTo,
} from "./AllComponents";

export function BiochemistryRoutes() {
  // Access the formName parameter from the URL
  const { BiochemistryformName } = useParams();

  // Render content based on the formName parameter
  let content = null;
  switch (BiochemistryformName) {
    case "BloodGroupHb":
      content = <BloodGroupHb />;
      break;
    case "BloodGroup":
      content = <BloodGroup />;
      break;
    case "FullBloodGroup":
      content = <FullBloodGroup />;
      break;
    case "Hb":
      content = <Hb />;
      break;
    case "UrineRE":
      content = <UrineRE />;
      break;
    case "StoolRE":
      content = <StoolRE />;
      break;
    case "Chemistry":
      content = <Chemistry />;
      break;
    case "Pregnancy":
      content = <Pregnancy />;
      break;
    case "Rbs":
      content = <Rbs />;
      break;
    case "AsoRa":
      content = <AsoRa />;
      break;
    case "WidalTo":
      content = <WidalTo />;
      break;
    case "ASO":
      content = <ASO />;
      break;
    case "RaFactor":
      content = <RaFactor />;
      break;
    case "HbsHcv":
      content = <HbsHcv />;
      break;
    case "HbHbsHcv":
      content = <HbHbsHcv />;
      break;
    case "HbsHcvRbs":
      content = <HbsHcvRbs />;
      break;
    case "HbBtCt":
      content = <HbBtCt />;
      break;
    case "Mt":
      content = <Mt />;
      break;
    case "GyneaWard":
      content = <GyneaWard />;
      break;
    default:
      content = <div>Form not found</div>;
  }

  return content;
}
