import { Card, Col, Container, Row, Table } from "react-bootstrap";
import "../../assets/css/page.css";

import { Link } from "react-router-dom";

const tests = [
  {
    testName: "S Cholestrol",
    link: "./Forms/RoutineTest/SCholestrol",
  },
  {
    testName: "HBS By Elisa",
    link: "./Forms/RoutineTest/HbsByElisa",
  },
  {
    testName: "PAP Smear For Cytology",
    link: "./Forms/RoutineTest/PapSmearForCytology",
  },
  {
    testName: "Progesterone",
    link: "./Forms/RoutineTest/Progesterone",
  },
  {
    testName: "Testosterone",
    link: "./Forms/RoutineTest/Testosterone",
  },
  {
    testName: "LH/FSH/Prolact",
    link: "./Forms/RoutineTest/LhFshProlact",
  },
  {
    testName: "FDPs",
    link: "./Forms/RoutineTest/FDPs",
  },
  {
    testName: "Torch",
    link: "./Forms/RoutineTest/Torch",
  },
  {
    testName: "Estradiol",
    link: "./Forms/RoutineTest/Estradiol",
  },
  {
    testName: "G6DP",
    link: "./Forms/RoutineTest/G6DP",
  },
  {
    testName: "GTT Male",
    link: "./Forms/RoutineTest/GttMale",
  },
  {
    testName: "GTT Female",
    link: "./Forms/RoutineTest/GttFemale",
  },
  {
    testName: "B.Urea/RBS",
    link: "./Forms/RoutineTest/BUreaRBS",
  },
  {
    testName: "Cultur",
    link: "./Forms/RoutineTest/Cultur",
  },
  {
    testName: "U/Sugar/ALB",
    link: "./Forms/RoutineTest/USugarALB",
  },
  {
    testName: "Toxoplasma",
    link: "./Forms/RoutineTest/Toxoplasma",
  },
  {
    testName: "Urea/Creatini",
    link: "./Forms/RoutineTest/UreaCreatini",
  },
  {
    testName: "Triglycerides",
    link: "./Forms/RoutineTest/Triglycerides",
  },
];

export default function RoutineTest() {
  const itemsPerRow = 5;

  // Split the tests into rows with 5 columns each
  const rows = tests.reduce((acc, test, index) => {
    const rowIndex = Math.floor(index / itemsPerRow);
    if (!acc[rowIndex]) {
      acc[rowIndex] = [];
    }
    acc[rowIndex].push(test);
    return acc;
  }, []);

  return (
    <section id="RoutineTest" className="RoutineTest">
      <Container fluid className="px-2">
        <Row className="text-white">
          <Col md={6}>
            <div className="text-start">
              <h2>RoutineTest</h2>
            </div>
          </Col>
          <Col md={6}>
            <div className="text-end mt-2">
              <p>Home/RoutineTest</p>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <Button variant="primary">Launch demo modal</Button> */}

      <Container fluid className="py-1 mb-4">
        <Row>
          <Card bg="dark" className="text-white">
            <Card.Header className="bg-primary text-dark">
              <h4>RoutineTest Test</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="p-0">
                  <Table variant="dark" bordered hover>
                    <tbody>
                      {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((test, colIndex) => (
                            <td key={colIndex}>
                              <Link className="btn btn-danger" to={test.link}>
                                {test.testName}
                              </Link>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </section>
  );
}
