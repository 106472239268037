import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import {
  NavBar, Home, Footer, Sidebar, SpecialTest, Serology, Haematology, Biochemistry, RoutineTest, BiochemistryRoutes, SpecialTestRoutes, RoutineTestRoutes, SerologyRoutes, HaematologyRoutes, Login
}
  from "./components/Routes/AllComponents";
import User from "./components/Pages/User";
import { ProtectedRoute } from "./components/Routes/protectedRoute/ProtectedRoute";
import { AuthProvider } from "./components/Routes/protectedRoute/AuthContext";

function App() {
  return (
    <AuthProvider>
      <Router>


        <div className="App">

          <header>
            <NavBar />
          </header>
          <main>

            <Container fluid>
              <Row>
                <Col md={2} className="p-0">
                  <Sidebar />
                </Col>
                <Col md={10} className="bg-content p-1">
                  <Routes>

                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<ProtectedRoute Component={Home} />} />

                    <Route path="/biochemistry" element={<ProtectedRoute Component={Biochemistry} />} />
                    <Route path="/biochemistry/Forms/Biochemistry/:BiochemistryformName" element={<ProtectedRoute Component={BiochemistryRoutes} />} />

                    <Route path="/specialTest" element={<ProtectedRoute Component={SpecialTest} />} />
                    <Route path="/specialTest/Forms/SpecialTest/:SpecialTestformName" element={<ProtectedRoute Component={SpecialTestRoutes} />} />

                    <Route path="/routineTest" element={<ProtectedRoute Component={RoutineTest} />} />
                    <Route path="/routineTest/Forms/RoutineTest/:RoutineTestformName" element={<ProtectedRoute Component={RoutineTestRoutes} />} />

                    <Route path="/serology" element={<ProtectedRoute Component={Serology} />} />
                    <Route path="/serology/Forms/Serology/:SerologyformName" element={<ProtectedRoute Component={SerologyRoutes} />} />

                    <Route path="/haematology" element={<ProtectedRoute Component={Haematology} />} />
                    <Route path="/haematology/Forms/Haematology/:HaematologyformName" element={<ProtectedRoute Component={HaematologyRoutes} />} />

                    <Route path="/user" element={<ProtectedRoute Component={User} />} />

                  </Routes>
                </Col>
              </Row>
            </Container>

          </main>
          <footer>
            <Footer />
          </footer>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
