import { Card, Col, Container, Row, Table } from "react-bootstrap";
import "../../assets/css/page.css";

import { Link } from "react-router-dom";

const tests = [
  {
    testName: "TLC/DLC/ESR",
    link: "./Forms/Serology/TlcDlcEsr",
  },
  {
    testName: "STOOL",
    link: "./Forms/Serology/STOOL",
  },
  {
    testName: "Brucella",
    link: "./Forms/Serology/Brucella",
  },
  {
    testName: "Urine C/S",
    link: "./Forms/Serology/UrineCs",
  },
  {
    testName: "HbsAg/HCV by Elisa",
    link: "./Forms/Serology/HbsAgHcvByElisa",
  },
  {
    testName: "E.Haemaglutination",
    link: "./Forms/Serology/EHaemaglutination",
  },
  {
    testName: "CMW",
    link: "./Forms/Serology/CMW",
  },
  {
    testName: "Pregnancy IN",
    link: "./Forms/Serology/PregnancyIN",
  },
  {
    testName: "U For Bence John's",
    link: "./Forms/Serology/UForBenceJohns",
  },
  {
    testName: "VDRl",
    link: "./Forms/Serology/VDRl",
  },
  {
    testName: "Urine For Sugar",
    link: "./Forms/Serology/UrineForSugar",
  },
  {
    testName: "S.FSH",
    link: "./Forms/Serology/SFsh",
  },
  {
    testName: "FBS",
    link: "./Forms/Serology/FBS",
  },
  {
    testName: "TLC GPT",
    link: "./Forms/Serology/TLCGpt",
  },
  {
    testName: "Reticulocytes",
    link: "./Forms/Serology/Reticulocytes",
  },
  {
    testName: "U For Ketone",
    link: "./Forms/Serology/UForKetone",
  },
  {
    testName: "Toxoplasmosis",
    link: "./Forms/Serology/Toxoplasmosis",
  },
  {
    testName: "24-Urine",
    link: "./Forms/Serology/twentyFourUrine",
  },
  {
    testName: "Typhidot",
    link: "./Forms/Serology/Typhidot",
  },
];

export default function Serology() {
  const itemsPerRow = 5;

  // Split the tests into rows with 5 columns each
  const rows = tests.reduce((acc, test, index) => {
    const rowIndex = Math.floor(index / itemsPerRow);
    if (!acc[rowIndex]) {
      acc[rowIndex] = [];
    }
    acc[rowIndex].push(test);
    return acc;
  }, []);

  return (
    <section id="Serology" className="Serology">
      <Container fluid className="px-2">
        <Row className="text-white">
          <Col md={6}>
            <div className="text-start">
              <h2>Serology</h2>
            </div>
          </Col>
          <Col md={6}>
            <div className="text-end mt-2">
              <p>Home/Serology</p>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <Button variant="primary">Launch demo modal</Button> */}

      <Container fluid className="py-1 mb-4">
        <Row>
          <Card bg="dark" className="text-white">
            <Card.Header className="bg-primary text-dark">
              <h4>Serology Test</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="p-0">
                  <Table variant="dark" bordered hover>
                    <tbody>
                      {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((test, colIndex) => (
                            <td key={colIndex}>
                              <Link className="btn btn-danger" to={test.link}>
                                {test.testName}
                              </Link>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </section>
  );
}
