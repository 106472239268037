import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import CommonTestForm from "../commonTestForm";

const host = process.env.REACT_APP_HOST;

export const MP = () => {
  const [tests, setTests] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [additionalData, setAdditionalData] = useState({ A: "", B: "", C: "" });

  // Function to fetch tests data
  const fetchTests = async () => {
    try {
      const response = await fetch(`${host}/haematology/MP/fetchall/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Fetch failed with status ${response.status}`);
      }

      const json = await response.json();
      setTests(json);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTests();
  }, []);

  // Function to handle form submission
  const handleAsoSubmit = async (commonFormData) => {
    const formData = {
      ...commonFormData,
      additionalData: additionalData,
    };

    try {
      const response = await fetch(`${host}/haematology/MP/addTest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const json = await response.json();
        const test = json;
        setTests([...tests, test]); // Update tests with the new test
        console.log("Form data submitted successfully");
      } else {
        console.error("Failed to submit form data");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    console.log("Combined Form Data:", formData);
  };

  // Function to handle additional data input change
  const handleAdditionalDataChange = (e) => {
    const { name, value } = e.target;
    setAdditionalData({ ...additionalData, [name]: value });
  };

  // Function to open the edit modal and set the selected test
  const handleEditClick = (test) => {
    setSelectedTest(test);
    setShowEditModal(true);
  };

  // Function to close the edit modal
  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleEditFieldChange = (e) => {
    const { name, value } = e.target;

    // Update the selectedTest state with the new value for the field.
    setSelectedTest({
      ...selectedTest,
      additionalData: {
        ...selectedTest.additionalData,
        [name]: value,
      },
      [name]: value,
    });
  };

  // Function to handle form submission for editing
  const handleEditSubmit = async () => {
    try {
      const response = await fetch(
        `${host}/haematology/MP/editTest/${selectedTest._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(selectedTest), // Send the selectedTest data for editing
        }
      );

      if (response.ok) {
        const updatedTests = tests.map((test) =>
          test._id === selectedTest._id ? selectedTest : test
        );
        setTests(updatedTests);
        console.log("Test edited successfully");
      } else {
        console.error("Failed to edit test");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }

    // Close the edit modal after submission
    handleCloseEditModal();
  };

  // Function to handle test deletion
  const handleDelete = async (testId) => {
    try {
      const response = await fetch(
        `${host}/haematology/MP/deleteTest/${testId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Remove the deleted test from the tests array
        const updatedTests = tests.filter((test) => test._id !== testId);
        setTests(updatedTests);
        console.log("Test deleted successfully");
      } else {
        console.error("Failed to delete test");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <Container className="px-5 pb-5 text-white" style={{ minHeight: "90vh" }}>
      <CommonTestForm onSubmit={(formData) => handleAsoSubmit(formData)} />
      <Container>
        <Row className="mt-5">
          <h3>Test Results</h3>
          <Col sm={4}>
            <Form.Group className="my-3" controlId="formBasicEmail">
              <Form.Label>A</Form.Label>
              <Form.Control
                type="text"
                name="A"
                placeholder=""
                value={additionalData.A}
                onChange={handleAdditionalDataChange}
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group className="my-3" controlId="formBasicEmail">
              <Form.Label>B</Form.Label>
              <Form.Control
                type="text"
                name="B"
                placeholder=""
                value={additionalData.B}
                onChange={handleAdditionalDataChange}
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group className="my-3" controlId="formBasicEmail">
              <Form.Label>C</Form.Label>
              <Form.Control
                type="text"
                name="C"
                placeholder=""
                value={additionalData.C}
                onChange={handleAdditionalDataChange}
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5">
        <Row>
          <Col sm={12}>
            <h2>ASO Tests</h2>
            <Table responsive bordered variant="dark">
              <thead>
                <tr>
                  <th className="bg-primary">Lab No</th>
                  <th className="bg-primary">Full Name</th>
                  <th className="bg-primary">Test Name</th>
                  <th className="bg-primary">Gender</th>
                  <th className="bg-primary">Referred By</th>
                  <th className="bg-primary">Additional Data A</th>
                  <th className="bg-primary">Additional Data B</th>
                  <th className="bg-primary">Additional Data C</th>
                  <th className="bg-primary">Operations</th>
                </tr>
              </thead>
              <tbody>
                {tests.map((test) => (
                  <tr key={test._id}>
                    <td>{test.labNo}</td>
                    <td>{test.fullName}</td>
                    <td>{test.test}</td>
                    <td>{test.gender}</td>
                    <td>{test.referred}</td>
                    <td>{test.additionalData.A}</td>
                    <td>{test.additionalData.B}</td>
                    <td>{test.additionalData.C}</td>
                    <td className="bg-danger">
                      <FontAwesomeIcon
                        icon={faEdit}
                        onClick={() => handleEditClick(test)}
                        style={{ cursor: "pointer", marginRight: "10px" }}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => handleDelete(test._id)}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {/* Edit Modal */}
            <Modal size="lg" show={showEditModal} onHide={handleCloseEditModal}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Test</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row>
                    <Col sm={6}>
                      {" "}
                      <Form.Group controlId="editFullName">
                        <Form.Label>Full Name:</Form.Label>
                        <Form.Control
                          type="text"
                          name="fullName"
                          value={selectedTest?.fullName || ""}
                          onChange={(e) => handleEditFieldChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="editFullName">
                        <Form.Label>Test Name:</Form.Label>
                        <Form.Control
                          type="text"
                          name="test"
                          value={selectedTest?.test || ""}
                          onChange={(e) => handleEditFieldChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="editFullName">
                        <Form.Label>Referred By:</Form.Label>
                        <Form.Control
                          as="select"
                          name="referred"
                          value={selectedTest?.referred || ""}
                          onChange={(e) => handleEditFieldChange(e)}
                        >
                          <option>Select Doctor</option>
                          <option value="umar">Dr.Muhammad Umar</option>
                          <option value="karim">Dr.Abdul Karim Afridi</option>
                          <option value="zarlasht">
                            Dr.Zarlasht Yousafzai
                          </option>
                          <option value="junaid">
                            Dr.Syed Muhammad Junaid
                          </option>
                          <option value="other">Other</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="gender">
                        <Form.Label>Gender:</Form.Label>
                        <Form.Control
                          as="select"
                          name="gender"
                          value={selectedTest?.gender || ""}
                          onChange={(e) => handleEditFieldChange(e)}
                        >
                          <option>Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="editFullName">
                        <Form.Label>Add A:</Form.Label>
                        <Form.Control
                          type="text"
                          name="A"
                          value={selectedTest?.additionalData.A || ""}
                          onChange={(e) => handleEditFieldChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="editFullName">
                        <Form.Label>Add B:</Form.Label>
                        <Form.Control
                          type="text"
                          name="B"
                          value={selectedTest?.additionalData.B || ""}
                          onChange={(e) => handleEditFieldChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group controlId="editFullName">
                        <Form.Label>Add C:</Form.Label>
                        <Form.Control
                          type="text"
                          name="C"
                          value={selectedTest?.additionalData.C || ""}
                          onChange={(e) => handleEditFieldChange(e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    className="mt-4"
                    variant="primary"
                    onClick={handleEditSubmit}
                  >
                    Save Changes
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
