import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const availableTests = [
  {
    testName: "Full Blood Group",
    link: "./Biochemistry/Forms/Biochemistry/FullBloodGroup",
  },
  {
    testName: "Urine R/E",
    link: "./Biochemistry/Forms/Biochemistry/UrineRE",
  },
  {
    testName: "Chemistry",
    link: "./Biochemistry/Forms/Biochemistry/Chemistry",
  },
  {
    testName: "Widal To",
    link: "./Biochemistry/Forms/Biochemistry/WidalTo",
  },
  {
    testName: "RA Factor",
    link: "./Biochemistry/Forms/Biochemistry/RaFactor",
  },
  {
    testName: "Lipid Profil",
    link: "./Haematology/Forms/Haematology/LipidProfil",
  },
];

export default function Home() {
  return (
    <>
      <h2 className="mt-2 text-light">Available Tests</h2>
      <Container className="d-flex">
        <Row>
          {availableTests.map((test) => (
            <Col>
              <Container className="m-3">
                <Card style={{ backgroundColor: "orange", width: "20rem" }}>
                  <Card.Body>
                    <Card.Title>{test.testName}</Card.Title>
                    <Table bordered variant="dark">
                      <tbody>
                        <tr>
                          <td>Total Tests:</td>
                          <td>66</td>
                        </tr>
                      </tbody>
                    </Table>
                    <Link className="btn btn-success" to={test.link}>
                      Go To Test
                    </Link>
                  </Card.Body>
                </Card>
              </Container>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}
