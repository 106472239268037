import { useParams } from "react-router-dom";
import {
  ANF,
  BtCt,
  Calcium,
  CpkCardia,
  ESR,
  HbBtCtBg,
  HbsHcvHivVd,
  LipidProfil,
  MP,
  NewXMatching,
  PeripheralSmear,
  PlatCount,
  PtAptt,
  SBR,
  SElectrolyte,
  SemenAnalysis,
  SerumAmylase,
  SputumForAfb,
  XMatching,
} from "./AllComponents";

export function HaematologyRoutes() {
  // Access the formName parameter from the URL
  const { HaematologyformName } = useParams();

  // Render content based on the formName parameter
  let content = null;
  switch (HaematologyformName) {
    case "LipidProfil":
      content = <LipidProfil />;
      break;
    case "SemenAnalysis":
      content = <SemenAnalysis />;
      break;
    case "SputumForAfb":
      content = <SputumForAfb />;
      break;
    case "SerumAmylase":
      content = <SerumAmylase />;
      break;
    case "PlatCount":
      content = <PlatCount />;
      break;
    case "SElectrolyte":
      content = <SElectrolyte />;
      break;
    case "Calcium":
      content = <Calcium />;
      break;
    case "HbsHcvHivVd":
      content = <HbsHcvHivVd />;
      break;
    case "NewXMatching":
      content = <NewXMatching />;
      break;
    case "PeripheralSmear":
      content = <PeripheralSmear />;
      break;
    case "HbBtCtBg":
      content = <HbBtCtBg />;
      break;
    case "ANF":
      content = <ANF />;
      break;
    case "MP":
      content = <MP />;
      break;
    case "SBR":
      content = <SBR />;
      break;
    case "BtCt":
      content = <BtCt />;
      break;
    case "ESR":
      content = <ESR />;
      break;
    case "CpkCardia":
      content = <CpkCardia />;
      break;
    case "PtAptt":
      content = <PtAptt />;
      break;
    case "XMatching":
      content = <XMatching />;
      break;
    default:
      content = (
        <div>
          <h1 className="text-light">Form not found</h1>
        </div>
      );
  }

  return content;
}
