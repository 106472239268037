import { useContext } from "react";
import "../../assets/css/footer.css";
import { Container, Row, Col } from "react-bootstrap";
import { AuthContext } from "../Routes/protectedRoute/AuthContext";

export default function Footer() {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <section id="footer">
      {isLoggedIn && (
        <div className="pt-5 footer">
          <Container>
            <Row>
              <Col lg={5} xs={12} className="about-company">
                <h2>RS Laboratory</h2>
                <p className="pr-5 text-white-50">
                  The Secure and Guaranteed Way Of Testing your results.
                </p>
                <p>
                  <a href="/">
                    <i className="fa fa-facebook-square mr-1"></i>
                  </a>
                  <a href="/">
                    <i className="fa fa-linkedin-square"></i>
                  </a>
                </p>
              </Col>
              <Col lg={3} xs={12} className="links">
                <h3 className="mt-lg-0 mt-sm-3">Links</h3>
                <ul className="m-0 p-0">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/">Tests</a>
                  </li>
                </ul>
              </Col>
              <Col lg={4} xs={12} className="location">
                <h3 className="mt-lg-0 mt-sm-4">Location</h3>
                <p>Village Amal Khel, Sheraki, Dara Adam Khel</p>
                <p className="mb-0">
                  <i className="fa fa-phone mr-3"></i>+92 3061633077
                </p>
                <p>
                  <i className="fa fa-envelope-o mr-3"></i>info@hsdf.com
                </p>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col className="copyright">
                <p className="top"></p>
                <p className="footer-p">
                  <small className="text-white-50">
                    © 2023. All Rights Reserved.
                  </small>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </section>
  );
}
