import React, { useState, useEffect } from "react";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if a token exists in localStorage
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }

    // Add an event listener to remove the token when the user refreshes or closes the website
    window.addEventListener("beforeunload", () => {
      localStorage.removeItem("token");
    });

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("beforeunload", () => {
        localStorage.removeItem("token");
      });
    };
  }, []);

  const login = (token) => {
    // Set the token in localStorage and update the isLoggedIn state
    localStorage.setItem("token", token);
    setIsLoggedIn(true);
  };

  const logout = () => {
    // Remove the token from localStorage and update the isLoggedIn state
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
