import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

const CommonTestForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    test: "",
    gender: "",
    referred: "",
    // labNo: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit(formData); // Pass the form data to the parent component for submission
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Container className="pt-3">
        <Row>
          <Col sm={9}>
            <h2 align="start" className="mb-3 p-2">
              Test Details
            </h2>
          </Col>
          <Col sm={3}>
            <Button
              className="px-3 mt-2 bg-danger"
              variant="dark"
              type="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Container>
      <Row>
        <Col sm={6}>
          <Form.Group controlId="fullName">
            <Form.Label>Full Name :</Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group controlId="test">
            <Form.Label>Test Name :</Form.Label>
            <Form.Control
              type="text"
              name="test"
              value={formData.test}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>

        {/* Additional common form fields go here */}
        <Col sm={6}>
          <Form.Group controlId="referred">
            <Form.Label>Referred By :</Form.Label>
            <Form.Control
              as="select"
              name="referred"
              value={formData.referred}
              onChange={handleInputChange}
            >
              <option>Select Doctor</option>
              <option value="umar">Dr.Muhammad Umar</option>
              <option value="karim">Dr.Abdul Karim Afridi</option>
              <option value="zarlasht">Dr.Zarlasht Yousafzai</option>
              <option value="junaid">Dr.Syed Muhammad Junaid</option>
              <option value="other">Other</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group controlId="gender">
            <Form.Label>Gender :</Form.Label>
            <Form.Control
              as="select"
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
            >
              <option>Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </Form.Control>
          </Form.Group>
        </Col>
        {/* <Col sm={6}>
          <Form.Group controlId="labNo">
            <Form.Label>Lab No :</Form.Label>
            <Form.Control
              type="text"
              name="labNo"
              value={formData.labNo}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col> */}
      </Row>
    </Form>
  );
};

export default CommonTestForm;
