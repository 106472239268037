import { Card, Col, Container, Row, Table } from "react-bootstrap";
import "../../assets/css/page.css";

import { Link } from "react-router-dom";

const tests = [
  {
    testName: "Biopsy",
    link: "./Forms/SpecialTest/Biopsy",
  },
  {
    testName: "B-HCG",
    link: "./Forms/SpecialTest/BHcg",
  },
  {
    testName: "VMA",
    link: "./Forms/SpecialTest/VMA",
  },
  {
    testName: "Renal Calculus",
    link: "./Forms/SpecialTest/RenalCalculus",
  },
  {
    testName: "Urine Albumine",
    link: "./Forms/SpecialTest/UrineAlbumine",
  },
  {
    testName: "U Acid",
    link: "./Forms/SpecialTest/UAcid",
  },
  {
    testName: "TFTs",
    link: "./Forms/SpecialTest/TFTs",
  },
  {
    testName: "TLC/HB/ESR",
    link: "./Forms/SpecialTest/TlcHbEsr",
  },
  {
    testName: "ICT",
    link: "./Forms/SpecialTest/ICT",
  },
  {
    testName: "GPT/Bilirubin",
    link: "./Forms/SpecialTest/GptBilirubin",
  },
  {
    testName: "FBC",
    link: "./Forms/SpecialTest/FBC",
  },
  {
    testName: "Acid Phosphate",
    link: "./Forms/SpecialTest/AcidPhosphate",
  },
  {
    testName: "HVS for C/S",
    link: "./Forms/SpecialTest/HvsForCs",
  },
  {
    testName: "Mix Test",
    link: "./Forms/SpecialTest/MixTest",
  },
  {
    testName: "TLC And ESR",
    link: "./Forms/SpecialTest/TlcAndEsr",
  },
  {
    testName: "Coomb",
    link: "./Forms/SpecialTest/Coomb",
  },
  {
    testName: "TOXO",
    link: "./Forms/SpecialTest/TOXO",
  },
  {
    testName: "Widal",
    link: "./Forms/SpecialTest/Widal",
  },
  {
    testName: "HCV By Elisa",
    link: "./Forms/SpecialTest/HcvByElisa",
  },
];

export default function SpecialTest(props) {
  const itemsPerRow = 5;

  // Split the tests into rows with 5 columns each
  const rows = tests.reduce((acc, test, index) => {
    const rowIndex = Math.floor(index / itemsPerRow);
    if (!acc[rowIndex]) {
      acc[rowIndex] = [];
    }
    acc[rowIndex].push(test);
    return acc;
  }, []);

  return (
    <section id="SpecialTest" className="SpecialTest">
      <Container fluid className="px-2">
        <Row className="text-white">
          <Col md={6}>
            <div className="text-start">
              <h2>SpecialTest</h2>
            </div>
          </Col>
          <Col md={6}>
            <div className="text-end mt-2">
              <p>Home/SpecialTest</p>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <Button variant="primary">Launch demo modal</Button> */}

      <Container fluid className="py-1 mb-4">
        <Row>
          <Card bg="dark" className="text-white">
            <Card.Header className="bg-primary text-dark">
              <h4>SpecialTest Test</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="p-0">
                  <Table variant="dark" bordered hover>
                    <tbody>
                      {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((test, colIndex) => (
                            <td key={colIndex}>
                              <Link className="btn btn-danger" to={test.link}>
                                {test.testName}
                              </Link>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </section>
  );
}
