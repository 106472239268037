import { useParams } from "react-router-dom";
import {
  BUreaRBS,
  Cultur,
  Estradiol,
  FDPs,
  G6DP,
  GttFemale,
  GttMale,
  HbsByElisa,
  LhFshProlact,
  PapSmearForCytology,
  Progesterone,
  SCholestrol,
  Testosterone,
  Torch,
  Toxoplasma,
  Triglycerides,
  USugarALB,
  UreaCreatini,
} from "./AllComponents";

export function RoutineTestRoutes() {
  // Access the formName parameter from the URL
  const { RoutineTestformName } = useParams();

  // Render content based on the formName parameter
  let content = null;
  switch (RoutineTestformName) {
    case "SCholestrol":
      content = <SCholestrol />;
      break;
    case "HbsByElisa":
      content = <HbsByElisa />;
      break;
    case "PapSmearForCytology":
      content = <PapSmearForCytology />;
      break;
    case "Progesterone":
      content = <Progesterone />;
      break;
    case "Testosterone":
      content = <Testosterone />;
      break;
    case "LhFshProlact":
      content = <LhFshProlact />;
      break;
    case "FDPs":
      content = <FDPs />;
      break;
    case "Torch":
      content = <Torch />;
      break;
    case "Estradiol":
      content = <Estradiol />;
      break;
    case "G6DP":
      content = <G6DP />;
      break;
    case "GttMale":
      content = <GttMale />;
      break;
    case "GttFemale":
      content = <GttFemale />;
      break;
    case "BUreaRBS":
      content = <BUreaRBS />;
      break;
    case "Cultur":
      content = <Cultur />;
      break;
    case "USugarALB":
      content = <USugarALB />;
      break;
    case "Toxoplasma":
      content = <Toxoplasma />;
      break;
    case "UreaCreatini":
      content = <UreaCreatini />;
      break;
    case "Triglycerides":
      content = <Triglycerides />;
      break;

    default:
      content = (
        <div>
          <h1 className="text-light">Form not found</h1>
        </div>
      );
  }

  return content;
}
