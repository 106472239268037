import { useParams } from "react-router-dom";
import {
  Brucella,
  CMW,
  EHaemaglutination,
  FBS,
  HbsAgHcvByElisa,
  PregnancyIN,
  Reticulocytes,
  SFsh,
  STOOL,
  TLCGpt,
  TlcDlcEsr,
  Toxoplasmosis,
  Typhidot,
  UForBenceJohns,
  UForKetone,
  UrineCs,
  UrineForSugar,
  VDRl,
} from "./AllComponents";

export function SerologyRoutes() {
  // Access the formName parameter from the URL
  const { SerologyformName } = useParams();

  // Render content based on the formName parameter
  let content = null;
  switch (SerologyformName) {
    case "TlcDlcEsr":
      content = <TlcDlcEsr />;
      break;
    case "STOOL":
      content = <STOOL />;
      break;
    case "Brucella":
      content = <Brucella />;
      break;
    case "UrineCs":
      content = <UrineCs />;
      break;
    case "HbsAgHcvByElisa":
      content = <HbsAgHcvByElisa />;
      break;
    case "EHaemaglutination":
      content = <EHaemaglutination />;
      break;
    case "CMW":
      content = <CMW />;
      break;
    case "PregnancyIN":
      content = <PregnancyIN />;
      break;
    case "UForBenceJohns":
      content = <UForBenceJohns />;
      break;
    case "VDRl":
      content = <VDRl />;
      break;
    case "UrineForSugar":
      content = <UrineForSugar />;
      break;
    case "SFsh":
      content = <SFsh />;
      break;
    case "FBS":
      content = <FBS />;
      break;
    case "TLCGpt":
      content = <TLCGpt />;
      break;
    case "Reticulocytes":
      content = <Reticulocytes />;
      break;
    case "UForKetone":
      content = <UForKetone />;
      break;
    case "Toxoplasmosis":
      content = <Toxoplasmosis />;
      break;
    case "twentyFourUrine":
      content = <twentyFourUrine />;
      break;
    case "Typhidot":
      content = <Typhidot />;
      break;
    default:
      content = (
        <div>
          <h1 className="text-light">Form not found</h1>
        </div>
      );
  }

  return content;
}
