import { useParams } from "react-router-dom";
import {
  AcidPhosphate,
  BHcg,
  Biopsy,
  Coomb,
  FBC,
  GptBilirubin,
  HcvByElisa,
  HvsForCs,
  ICT,
  MixTest,
  RenalCalculus,
  TFTs,
  TOXO,
  TlcAndEsr,
  TlcHbEsr,
  UAcid,
  UrineAlbumine,
  VMA,
  Widal,
} from "./AllComponents";

export function SpecialTestRoutes() {
  // Access the formName parameter from the URL
  const { SpecialTestformName } = useParams();

  // Render content based on the formName parameter
  let content = null;
  switch (SpecialTestformName) {
    case "Biopsy":
      content = <Biopsy />;
      break;
    case "BHcg":
      content = <BHcg />;
      break;
    case "VMA":
      content = <VMA />;
      break;
    case "RenalCalculus":
      content = <RenalCalculus />;
      break;
    case "UrineAlbumine":
      content = <UrineAlbumine />;
      break;
    case "UAcid":
      content = <UAcid />;
      break;
    case "TFTs":
      content = <TFTs />;
      break;
    case "TlcHbEsr":
      content = <TlcHbEsr />;
      break;
    case "ICT":
      content = <ICT />;
      break;
    case "GptBilirubin":
      content = <GptBilirubin />;
      break;
    case "FBC":
      content = <FBC />;
      break;
    case "AcidPhosphate":
      content = <AcidPhosphate />;
      break;
    case "HvsForCs":
      content = <HvsForCs />;
      break;
    case "MixTest":
      content = <MixTest />;
      break;
    case "TlcAndEsr":
      content = <TlcAndEsr />;
      break;
    case "Coomb":
      content = <Coomb />;
      break;
    case "TOXO":
      content = <TOXO />;
      break;
    case "Widal":
      content = <Widal />;
      break;
    case "HcvByElisa":
      content = <HcvByElisa />;
      break;
    default:
      content = (
        <div>
          <h1 className="text-light">Form not found</h1>
        </div>
      );
  }

  return content;
}
