import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Routes/protectedRoute/AuthContext";

const host = process.env.REACT_APP_HOST;

export const Login = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${host}/login/fetchUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginDetails),
      });

      const json = await response.json();

      if (json.token) {
        let token = json.token;
        login(token);
        navigate("/");
      }

      if (json.success) {
        console.log("User is authenticated. You can handle login here.");
      } else {
        console.error("Authentication failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh", backgroundColor: "rgb(34 35 35)" }}
    >
      <Row style={{ minWidth: "40vw" }}>
        <Col sm={12}>
          <Card style={{ width: "100%", minHeight: "60vh" }}>
            <Card.Header className="bg-primary text-light">
              <h1 className="text-center">RS LAB</h1>
            </Card.Header>
            <Card.Body>
              <Card.Title className="text-center mt-1">Login Form</Card.Title>

              <Form className="mt-4" onSubmit={handleLogin}>
                <Form.Group controlId="formBasicEmail" className="my-3">
                  <Form.Label className="d-flex">
                    <FontAwesomeIcon className="mx-2" icon={faEnvelope} /> Email
                    address
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={loginDetails.email}
                    onChange={(e) =>
                      setLoginDetails({
                        ...loginDetails,
                        email: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="my-3">
                  <Form.Label className="d-flex">
                    <FontAwesomeIcon className="mx-2" icon={faLock} /> Password
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={loginDetails.password}
                    onChange={(e) =>
                      setLoginDetails({
                        ...loginDetails,
                        password: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>

                <Button
                  className="m-3 px-5 py-3"
                  variant="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
